import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Table } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/table/customisation",
  "title": "Table - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component.`}</p>
    <p>{`See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--content-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom background color for the table content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--header-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom background color for table headers.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div
  style={{
    maxWidth: '640px',
  }}
>
  <Table
    caption={
      <span>
        <b>Table 1</b>: Table description
      </span>
    }
    cols={[
      {
        headerName: 'Not rendered',
        key: 'id',
      },
      {
        headerName: 'First name',
        key: 'firstName',
      },
      {
        headerName: 'Surname',
        key: 'surname',
      },
      {
        headerName: 'Age',
        key: 'age',
        transform: function noRefCheck() {},
      },
      {
        headerName: 'Profession',
        key: 'profession',
      },
    ]}
    indexKey="id"
    rows={[
      {
        age: 39,
        firstName: 'Lauri',
        id: 1000,
        profession: 'Engineer',
        surname: 'Kekkonen',
      },
      {
        age: 62,
        firstName: 'Maria',
        id: 1001,
        profession: 'Designer',
        surname: 'Sarasoja',
      },
      {
        age: 50,
        firstName: 'Anneli',
        id: 1002,
        profession: 'Meteorologist',
        surname: 'Routa',
      },
      {
        age: 18,
        firstName: 'Osku',
        id: 1003,
        profession: 'Mail Carrier',
        surname: 'Rausku',
      },
    ]}
    theme={{
      '--content-background-color': 'var(--color-black-5)',
      '--header-background-color': 'var(--color-tram)',
    }}
    variant="dark"
  />
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      